jQuery(document).ready(function($) {
  $('.header__nav__toggle').click(function() {
    $('.header__menu-wrapper').fadeIn(200);
    return false;
  });

  $('.header__menu__close').click(function() {
    $('.header__menu-wrapper').fadeOut(200);
    return false;
  });

  $('.header__menu-wrapper').click(function(event) {
    event.stopPropagation();
    $('.header__menu-wrapper').fadeOut(200);
    return false;
  });

  $('.header__menu').click(function(event) {
    event.stopPropagation();
  });

  $('.peoples-list__item').hover(
    function() {
      $(this)
        .find('.peoples-list__item__info')
        .fadeIn(500);
    },
    function() {
      $(this)
        .find('.peoples-list__item__info')
        .fadeOut(500);
    }
  );

  if ($('#range').length) {
    var rangeStart = 2000;
    if (typeof $('#range').data('start') !== 'undefined') {
      rangeStart = parseInt($('#range').data('start'));
    }

    var rangeEnd = 2020;
    if (typeof $('#range').data('end') !== 'undefined') {
      rangeEnd = parseInt($('#range').data('end'));
    }

    var range = document.getElementById('range');
    noUiSlider.create(range, {
      range: {
        min: 2000,
        max: 2020
      },

      start: [rangeStart, rangeEnd],
      step: 1,
      orientation: 'horizontal',
      tooltips: true,
      margin: 1,

      format: wNumb({
        decimals: 0
      }),

      // Show a scale with the slider
      pips: {
        mode: 'range',
        stepped: true,
        density: 4
      }
    });

    range.noUiSlider.on('set.one', function(values) {
      $('#filter-year-start').val(values[0]);
      $('#range').data('start', values[0]);

      $('#filter-year-end').val(values[1]);
      $('#range').data('end', values[1]);
    });
  }

  $('.filter__toggle').click(function() {
    $('.filter-wrapper').fadeIn(200);
    return false;
  });

  $('.filter__close').click(function() {
    $('.filter-wrapper').fadeOut(200);
    return false;
  });

  $('.filter-wrapper').click(function(event) {
    event.stopPropagation();
    $('.filter-wrapper').fadeOut(200);
    return false;
  });

  $('.filter').click(function(event) {
    event.stopPropagation();
  });

  $('.filter__menu li').mousedown(function() {
    if ($(this).hasClass('checked_all') == false) {
      $('.checked_all').removeClass('active');
      $('.checked_all_input').prop('checked', false);
    } else {
      $('.checked_item').removeClass('active');
      $('.checked_item_input').prop('checked', false);
    }
    $(this).toggleClass('active');
  });

  // -------------------------------------
  // Reference slider
  // -------------------------------------

  if ($('.js-slider').length) {
    new Glide('.js-slider', {
      //type: "carousel",
      focusAt: 'center',
      perView: 1,
      gap: 60
    }).mount();
  }

  // spuštění galerie
  if ($('.js-lightbox').length) {
    $('.js-lightbox').magnificPopup({
      type: 'image',
      removalDelay: 300,
      mainClass: 'mfp-fade',
      gallery: {
        enabled: true,
        tCounter: ''
      }
    });
  }

  /*
  var pageHeight = $(window).height();
  var heroHeight = $('.hero').height();

  // calculate how many pixels user should scroll until html changes:
  var count = 100,
    divide = pageHeight / count;

  $(document).scroll(function(e) {
    var scrollPosition = $(window).scrollTop(),
      relevantToHeight = Math.round((scrollPosition * count) / pageHeight) * 60;
    console.log(relevantToHeight + ' === ' + heroHeight);

    if (relevantToHeight < heroHeight + 100) {
      //window.scrollTo(0, 0);
      $('.hero').css(
        'transform',
        'translate3D(0,' + relevantToHeight + 'px, 0)'
      );
    }
  });
  */

  new WOW().init();
});

jQuery(window).load(function() {
  jQuery('.news__list--masonry').masonry({
    // set itemSelector so .grid-sizer is not used in layout
    itemSelector: '.news__item',
    gutter: '.news__item-gutter',
    fitWidth: true
  });
});
